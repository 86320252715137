import React from 'react';
import styles from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

const toolbar = (props) => (
    <header className={styles['Toolbar']}>
        <div className={styles['BarLogo']}>
            <Logo/>
        </div>
    </header>
);

export default toolbar;