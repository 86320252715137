import React, {Component} from 'react';
import styles from './PromotionalPanel.module.css';

class PromotionalPanel extends Component {


    render() { 
        return (
            <div className={styles['PromotionalPanel']}>
            <h3>{this.props.title}</h3>
            <p>{this.props.children}</p>
            </div>
        );
    }
}

export default PromotionalPanel;