import React, {Component} from 'react';
import Aux from '../../hoc/Aux';
import { throwStatement } from '@babel/types';

import PromotionalPanel from '../PromotionalPanel/PromotionalPanel';
import ImagePanel from '../ImagePanel/ImagePanel';
import ClickableImagePanel from '../ImagePanel/ClickableImagePanel';

import mainImage from '../../assets/images/queen/mainpage.jpg';
import bed1Image from '../../assets/images/queen/bedroom-1-150x150.jpg';
import bed2Image from '../../assets/images/queen/bedroom-2-150x150.jpg';
import bed3Image from '../../assets/images/queen/bedroom-3-150x150.jpg';
import bed4Image from '../../assets/images/queen/bedroom-4-150x150.jpg';
import bed5Image from '../../assets/images/queen/bedroom-5-150x150.jpg';
import kit1Image from '../../assets/images/queen/Kitchen-1-150x150.jpg';
import kit2Image from '../../assets/images/queen/Kitchen-2-150x150.jpg';
import kit3Image from '../../assets/images/queen/Kitchen-3-150x150.jpg';
import liv1Image from '../../assets/images/queen/Living-150x150.jpg';

import styles from './Welcome.module.css';


class Welcome extends Component{
    constructor(props){
        super(props);
    }

    render(){
        
        return (
            <Aux>
            <ClickableImagePanel imagesource={mainImage} max='100%'>
                <div>
                <ImagePanel imagesource={bed1Image}/>
                <ImagePanel imagesource={bed2Image}/>
                <ImagePanel imagesource={bed3Image}/>
                <ImagePanel imagesource={bed4Image}/>
                <ImagePanel imagesource={bed5Image}/>
                </div>
                <div>
                <ImagePanel imagesource={kit1Image}/>
                <ImagePanel imagesource={kit2Image}/>
                <ImagePanel imagesource={kit3Image}/>
                <ImagePanel imagesource={liv1Image}/>
                </div>                
            </ClickableImagePanel>
            <div className={styles['WelcomeItems']}>
            <PromotionalPanel title='Live like you mean it'>
                The best in student living for University of Wilfred Laurier, Conestoga College, and Nippising students - you will have the privacy and lifestyle you deserve in an off campus student community that is built specifically with you, the student, in mind.
            </PromotionalPanel>
            <PromotionalPanel title='Location, Location, Location'>
                Take advantage of our convenient location, just a step or two from campus, and unmatched amenities. Everything you want and need in one place: located adjacent to WLU, Conestoga and Nippising in Brantford. As an added convenience, Commonwealth Place is within walking distance of the Via rail station, LCBO, Fresh Co., and all academic buildings in the downtown core
            </PromotionalPanel>
            <PromotionalPanel title='Best of amenities offered'>
                Large common areas, huge private bedrooms w/ large closets & storage, professionally designed and decorated, fully furnished, loads of upgraded features, granite counter tops, high ceilings, stainless steel appliances, hardwood style flooring, 24/7 on-call staff and much more.
            </PromotionalPanel>
            </div>
            </Aux>
        );
    }
}

export default Welcome;