import React, {Component} from 'react';
import styles from './ImagePanel.module.css';

class ImagePanel extends Component {

    render() { 
        let view = <img src={this.props.imagesource} alt='Ambrosia Rentals' style={{width: this.props.max}}/>;
        return ( view );
    }
}

export default ImagePanel;