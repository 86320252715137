import React from 'react';

import burgerLogo from '../../assets/images/user-home-symbolic.svg';
import styles from './Logo.module.css';

const logo = (props) => (
    <div className={styles['Logo']} style={{height: props.height}}>
        <img src={burgerLogo} alt='Ambrosia Rentals'/> Ambrosia
    </div>
);

export default logo;