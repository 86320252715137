import React, {Component} from 'react';
import styles from './App.module.css';
import Layout from './components/Layout/Layout';
/*import BurgerBuilder from './containers/BurgerBuilder/BurgerBuilder';*/
import Welcome from './containers/Welcome/Welcome';

class App extends Component {

  state = {
    show: true
  };

  componentDidMount(){
    /*  To test the unloading of interceptors from the withErrorHandler class.
    setTimeout(()=>{
      this.setState({show:false});
    }, 15000);
    */
  }

  render(){
    return (
      <div className={styles['App']}>
        <Layout>
          {this.state.show?<Welcome></Welcome> : null}
        </Layout>
      </div>
    );
  }
}

export default App;
