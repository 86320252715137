import React, {Component} from 'react';
import styles from './ImagePanel.module.css';

class ClickableImagePanel extends Component {

    state = {mainclicked: false}

    imageClicked = () => {
        debugger;

        let newState = !this.state.mainclicked;
        this.setState({mainclicked: newState});
    }

    render() { 
        let images = [];
        if(this.state.mainclicked){
            let childList = this.props.children;

            for(let i=0;i<childList.length;i++){
                console.log(i, childList[i]);
                /*aDiv.push(childList[i]);*/
                images.push(childList[i]);
            }
        }else{
            images = <img src={this.props.imagesource} 
                alt='Ambrosia Rentals' 
                style={{width: this.props.max}}/>;
        }
        let view = <div className={styles['ImagePanel']} onClick={this.imageClicked}>
                {images}
            </div>;
        return ( view );
    }
}

export default ClickableImagePanel;